import { AsyncPipe } from "@angular/common";
import { MatListModule } from "@angular/material/list";
import { Component, ViewEncapsulation, inject } from "@angular/core";

import { UserRole } from "../../enums";
import { SidebarItem } from "../../interfaces";
import { SidebarListItemComponent } from "../sidebar-list-item/sidebar-list-item.component";
import { HttpService, SidebarToggleService } from "../../services";

import { Permissions } from "src/assets/config/config";

@Component({
  selector: "app-sidebar",
  standalone: true,
  imports: [AsyncPipe, MatListModule, SidebarListItemComponent],
  templateUrl: "./sidebar.component.html",
  styleUrl: "./sidebar.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
  userData = inject(HttpService).convertTokenJWT();
  roleCode = this.userData?.RoleCode as UserRole;
  isSidebarOpened$ = inject(SidebarToggleService).isSidebarOpened$;
  sidebarItems: SidebarItem[] = [
    {
      label: "SERVICES.TITLE",
      icon: "db-icon-service",
      routerLinkName: "/services",
      permissions: Permissions.Services,
    },
    {
      label: "TICKETS.MY_RESERVATIONS",
      icon: "db-icon-ticket",
      routerLinkName: "/my-tickets",
      permissions: Permissions.MyTickets,
    },
    {
      label: "ADMIN_MANAGEMENT",
      icon: "db-icon-cog",
      routerLinkName: "/admin",
      permissions: Permissions.Admin,
      children: [
        {
          label: "DASHBOARD_TITLE",
          icon: "db-icon-raci-matrix",
          routerLinkName: "/admin/dashboard",
          permissions: Permissions.Dashboard,
        },
        {
          label: "CALENDAR.TITLE",
          icon: "db-icon-calendar",
          routerLinkName: "/admin/calendar",
          permissions: Permissions.Calendar,
        },
        {
          label: "SERVICES.TITLE",
          icon: "db-icon-service",
          routerLinkName: "/admin/booking-services",
          permissions: Permissions.BookingServices,
        },
        {
          label: "TICKETS.RESERVATIONS",
          icon: "db-icon-reservation",
          routerLinkName: "/admin/reservations",
          permissions: Permissions.Reservations,
        },
        {
          label: "USERS.USERS_MANAGEMENT",
          icon: "db-icon-user",
          routerLinkName: "/admin/users-management",
          permissions: Permissions.UsersManagement,
        },
        {
          label: "SERVICES.SERVICES_MANAGEMENT",
          icon: "db-icon-services-management",
          routerLinkName: "/admin/services-management",
          permissions: Permissions.ServicesManagement,
        },
        {
          label: "REQUESTS.MANAGEMENT",
          icon: "db-icon-change-request",
          routerLinkName: "/admin/requests-management",
          permissions: Permissions.RequestsManagement,
        },
        {
          label: "LEARNING_CENTER.TITLE",
          icon: "db-icon-group",
          routerLinkName: "/admin/learning-center",
          permissions: Permissions.LearningCenter,
        },
        {
          label: "FRIENDS_OF_OMAN_MUSEUM.DEPARTMENT_PRIVILEGES",
          icon: "db-icon-museum-friend",
          routerLinkName: "/admin/friends-of-oman-privilages",
          permissions: Permissions.Memberships,
        },
        {
          label: "EVENTS.TITLE",
          icon: "db-icon-calendar-event",
          routerLinkName: "/admin/exhibitions-events",
          permissions: Permissions.Events,
        },
        {
          label: "REQUESTS.SCHOOL_UNIVERSITY_REQUESTS",
          icon: "db-icon-change-request",
          routerLinkName: "/admin/school-university-visits",
          permissions: Permissions.LearningCenter,
        },
        {
          label: "AUDIT_MONITOR.TITLE",
          icon: "db-icon-monitor",
          routerLinkName: "/admin/audit-monitors",
          permissions: Permissions.Audit,
        },
        {
          label: "REPORTS.TITLE",
          icon: "db-icon-sheet",
          routerLinkName: "/admin/reports",
          permissions: Permissions.Reports,
        },
      ],
    },
  ];

  constructor() {
    if (this.userData?.HaveMembership) {
      this.sidebarItems.push({
        label: "FRIENDS_OF_OMAN_MUSEUM.EVALUATIONS_AND_INTERESTS",
        icon: "db-icon-star",
        routerLinkName: "/evaluations-interests",
        permissions: Permissions.EvaluationsInterests,
      });
    }
  }
}
