import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";

import { User, UserPayload } from "../../interfaces";

import { GetPagedBody, GetPagedResponse, HttpService, HttpServiceBaseCRUD } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class UsersService extends HttpService implements HttpServiceBaseCRUD {
  protected get baseUrl(): string {
    return "users/";
  }

  currentUser = toSignal(this.getById());

  add(body: UserPayload) {
    return this.post<User>({ APIName: "add", body, showAlert: true });
  }

  update(body: UserPayload) {
    return this.put<User>({ APIName: "update", body, showAlert: true });
  }

  remove(id: string) {
    return this.delete({ APIName: `deletesoft/${id}`, showAlert: true });
  }

  getById(id: string = this.convertTokenJWT()?.UserId ?? "") {
    if (!id) return of(null);
    return this.get<User | null>({ APIName: `get/${id}` });
  }

  getAll() {
    return this.get<User[]>({ APIName: "getall" });
  }

  getPaged(body: GetPagedBody<Record<string, string | number | boolean>>) {
    return this.post<GetPagedResponse<User[]>>({ APIName: "getpaged", body });
  }

  getUserRoles(userId: string) {
    return this.get<User[]>({ APIName: `GetUserRoles/${userId}` });
  }

  getUserPermissions(userId: string) {
    return this.get<any[]>({ APIName: `GetUserPermissions/${userId}` });
  }
}
