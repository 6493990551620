import { register } from "swiper/element/bundle";
import { SwiperOptions } from "swiper/types";
import { NgTemplateOutlet } from "@angular/common";
import { Component, ContentChild, CUSTOM_ELEMENTS_SCHEMA, input, signal, TemplateRef, ViewEncapsulation } from "@angular/core";

import { SwiperDirective } from "../../directives";

@Component({
  selector: "swiper",
  standalone: true,
  imports: [SwiperDirective, NgTemplateOutlet],
  templateUrl: "./swiper.component.html",
  styleUrl: "./swiper.component.scss",
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SwiperComponent {
  dir = input<"ltr" | "rtl">("ltr");
  config = input<SwiperOptions>({});
  slides = input.required<unknown[]>();
  slideClass = input<string>();
  containerClass = input<string>("");

  defaultConfig = signal<SwiperOptions>({
    modules: [],
    injectStyles: [
      `
        :host {
          --swiper-theme-color: var(--bs-primary);
          height: 100%;
        }
      `,
    ],
    mousewheel: false,
    autoHeight: true,
    pagination: false,
    navigation: false,
    grabCursor: true,
    allowTouchMove: true,
    centeredSlides: false,
    loop: false,
    speed: 500,
    spaceBetween: 40,
    slidesPerView: 3,
  });

  @ContentChild("swiperSlide", { read: TemplateRef }) slideTemplateRef!: TemplateRef<unknown>;
  // slideTemplateRef = contentChild("swiperSlide", { read: TemplateRef }) as Signal<TemplateRef<unknown> | null>;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.defaultConfig.set({ ...this.defaultConfig(), ...this.config() });
    register();
  }
}
