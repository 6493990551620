import { Component } from "@angular/core";

@Component({
  selector: "app-swiper-slide",
  standalone: true,
  imports: [],
  templateUrl: "./swiper-slide.component.html",
  styleUrl: "./swiper-slide.component.scss",
})
export class SwiperSlideComponent {}
