import { isDevMode } from "@angular/core";
import { Routes } from "@angular/router";

export const museumBuildingsRoutes: Routes = [
  {
    path: "",
    title: "MUSEUM_BUILDINGS.TITLE",
    loadComponent: () =>
      import("./pages/museums-buildings-list/museums-buildings-list.component").then((m) => m.MuseumsBuildingsListComponent),
  },
];

export const museumBuildingsManagementRoutes: Routes = [
  {
    path: "add",
    title: "MUSEUM_BUILDINGS.ADD",
    canMatch: [() => isDevMode()],
    loadComponent: () =>
      import("./pages/add-edit-museum-buildings/add-edit-museum-building.component").then((m) => m.AddEditMuseumBuildingComponent),
  },
  {
    path: "edit/:id",
    title: "MUSEUM_BUILDINGS.EDIT",
    loadComponent: () =>
      import("./pages/add-edit-museum-buildings/add-edit-museum-building.component").then((m) => m.AddEditMuseumBuildingComponent),
  },
];
