import { subYears } from "date-fns";
import { toSignal } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ControlContainer, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Component, effect, inject, Injector, runInInjectionContext, Signal, signal } from "@angular/core";

import { Dropdown, LookupsService, UserRole } from "src/app/core";
import { InputTextComponent, DropdownComponent, AttachmentComponent, DatepickerComponent } from "src/app/shared";

const SHARED = [AttachmentComponent, InputTextComponent, DropdownComponent, DatepickerComponent];

@Component({
  selector: "app-person-request-details",
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, ...SHARED],
  templateUrl: "./person-request-details.component.html",
  styleUrl: "./person-request-details.component.scss",
})
export class PersonRequestDetailsComponent {
  private injector = inject(Injector);
  private activatedRoute = inject(ActivatedRoute);
  private lookupsService = inject(LookupsService);
  private controlContainer = inject(ControlContainer);

  user = this.lookupsService.convertTokenJWT();
  itemId = this.activatedRoute.snapshot.paramMap.get("id");
  genders = toSignal(this.lookupsService.getGenders());
  countries = toSignal(this.lookupsService.getCountries());
  nationalities = toSignal(this.lookupsService.getNationalities());
  governorates: Signal<Dropdown[] | undefined> = signal([]);
  states: Signal<Dropdown[] | undefined> = signal([]);
  residenceTypes = toSignal(this.lookupsService.getResidenceTypes());
  employmentTypes = toSignal(this.lookupsService.getEmploymentTypes());
  isDisabilitySignal = toSignal(this.isDisability?.valueChanges!);
  membershipCardPlans: Signal<Dropdown[] | undefined> | undefined;
  readonly = this.user?.RoleCode !== UserRole.ADMIN;
  birthDateMaxDate = subYears(new Date(), 12);

  allowedTypes = [
    { maxSize: 5, type: "png" },
    { maxSize: 5, type: "jpg" },
    { maxSize: 5, type: "jpeg" },
    { maxSize: 5, type: "pdf" },
  ];
  cardCoverAllowedTypes = [
    { maxSize: 5, type: "png" },
    { maxSize: 5, type: "jpg" },
    { maxSize: 5, type: "jpeg" },
  ];
  yesNoOptions: Dropdown[] = [
    { id: true, nameEn: "Yes", nameAr: "نعم", code: "YES" },
    { id: false, nameEn: "No", nameAr: "لا", code: "NO" },
  ];

  get form() {
    return this.controlContainer.control as FormGroup;
  }

  get isDisabilityAttachmentEnabled() {
    return this.form.get("disabilityAttachment")?.enabled;
  }

  private get isDisability() {
    return this.form.get("isDisability");
  }

  private get membershipCardPlanId() {
    return this.form.get("membershipCardPlanId");
  }

  private get totalAmount() {
    return this.form?.get("totalAmount");
  }

  constructor() {
    this.lookupsService.assignMembershipCardPlans();
    const membershipCardPlans = this.lookupsService.membershipCardPlans;

    effect(() => {
      this.membershipCardPlans = membershipCardPlans && signal(this.displayMembershipCardPlans(membershipCardPlans()!));

      if (this.itemId && this.form.get("permanentAddressDetails")?.get("countryId")?.value) {
        this.bindingLookups();
      }
    });
  }

  getGovernorates(id: number) {
    runInInjectionContext(this.injector, () => (this.governorates = toSignal(this.lookupsService.getGovernorates({ id }))));
  }

  getStates(id: number) {
    runInInjectionContext(this.injector, () => (this.states = toSignal(this.lookupsService.getStates({ id }))));
  }

  private bindingLookups() {
    this.lookupsService
      .getGovernorates({ id: this.form.get("permanentAddressDetails")?.get("countryId")?.value! })
      .subscribe((governorates) => (this.governorates = signal(governorates)));

    if (this.form.get("permanentAddressDetails")?.get("governorateId")?.value) {
      this.lookupsService
        .getStates({ id: this.form.get("permanentAddressDetails")?.get("governorateId")?.value! })
        .subscribe((states) => (this.states = signal(states)));
    }
  }

  private displayMembershipCardPlans(membershipCardPlans: Dropdown[]) {
    if (!membershipCardPlans) return [];
    if (!this.itemId) {
      this.totalAmount?.reset();
      this.membershipCardPlanId?.reset();
    }

    if (this.isDisabilitySignal()) {
      return membershipCardPlans.filter((membershipCardPlan) => membershipCardPlan.code === "DIS1YI");
    }

    return membershipCardPlans.filter((membershipCardPlan) => {
      return membershipCardPlan.type === "Individuals" && membershipCardPlan.code !== "DIS1YC" && membershipCardPlan.code !== "DIS1YI";
    });
  }
}
