import { Route, Routes } from "@angular/router";

export const schoolUniversityVisitsRoute: Route = {
  path: "school-university-visits",
  title: "REQUESTS.SCHOOL_UNIVERSITY_REQUESTS",
  data: { reservationTicketType: 2 },
  loadComponent: () => import("./pages/reservation-requests/reservation-requests.component").then((c) => c.ReservationRequestsComponent),
};

export const membershipRequestsRoute: Route = {
  path: "membership-requests",
  title: "FRIENDS_OF_OMAN_MUSEUM.MEMBERSHIP_REQUESTS",
  loadComponent: () => import("./pages/membership-requests/membership-requests.component").then((c) => c.MembershipRequestsComponent),
};

export const officialInstitutionsRoute: Route = {
  path: "official-institutions",
  title: "OFFICIAL_INSTITUTIONS.TITLE",
  data: { reservationTicketType: 7 },
  loadComponent: () => import("./pages/reservation-requests/reservation-requests.component").then((c) => c.ReservationRequestsComponent),
};

export const tourismCompaniesRoute: Route = {
  path: "tourism-companies",
  title: "SERVICES.TourismCompanies",
  data: { reservationTicketType: 6 },
  loadComponent: () => import("./pages/reservation-requests/reservation-requests.component").then((c) => c.ReservationRequestsComponent),
};

export const guidedToursRoute: Route = {
  path: "guided-tours",
  title: "SERVICES.GuidedTours",
  data: { reservationTicketType: 5 },
  loadComponent: () => import("./pages/reservation-requests/reservation-requests.component").then((c) => c.ReservationRequestsComponent),
};

export const requestsManagementRoutes: Routes = [
  {
    path: "",
    title: "REQUESTS.MANAGEMENT",
    data: {
      tabs: [
        {
          key: "OfficialInstitutions",
          title: "OFFICIAL_INSTITUTIONS.TITLE",
          routerLink: "official-institutions",
        },
        {
          key: "TourismCompanies",
          title: "SERVICES.TourismCompanies",
          routerLink: "tourism-companies",
        },
        {
          key: "GuidedTours",
          title: "SERVICES.GuidedTours",
          routerLink: "guided-tours",
        },
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),
    children: [
      {
        path: "",
        redirectTo: "official-institutions",
        pathMatch: "full",
      },
      officialInstitutionsRoute,
      tourismCompaniesRoute,
      guidedToursRoute,
    ],
  },
];
