import { inject } from "@angular/core";
import { Route, Router } from "@angular/router";
import { AuthService, EventServicesCodes, ParentServiceCode, TourServicesCodes } from "src/app/core";
import { productRoutes } from "./components/ticket-forms/products";

interface ParentServiceRoutes extends Route {
  path: keyof typeof ParentServiceCode;
}

interface TourServiceRoutes extends Route {
  path: keyof typeof TourServicesCodes;
}

interface EventServiceRoutes extends Route {
  path: keyof typeof EventServicesCodes;
}

// -----------------------------------------------------------------------------------------------

/**
 * @description children routes of the tour services
 */
const childrenTourBookRoutes: TourServiceRoutes[] = [
  {
    path: "EntranceTicket",
    loadComponent: () => import("./components/ticket-forms/regular-ticket/regular-ticket.component").then((c) => c.RegularTicketComponent),
  },
  {
    path: "FreeEntry",
    loadComponent: () => import("./components/ticket-forms/free-entry/free-entry.component").then((c) => c.FreeEntryComponent),
  },
  {
    path: "GroupOffers",
    loadComponent: () => import("./components/ticket-forms/group-offers/group-offers.component").then((c) => c.GroupOffersComponent),
  },
  {
    path: "SchoolUniversityVisits",
    loadComponent: () =>
      import("./components/ticket-forms/student-visit-ticket/student-visit-ticket.component").then((c) => c.StudentVisitTicketComponent),
  },
  {
    path: "Packages",
    loadComponent: () => import("./components/ticket-forms/packages/packages.component").then((c) => c.PackagesComponent),
  },
  {
    path: "GuidedTours",
    loadComponent: () => import("./components/ticket-forms/guided-tours/guided-tours.component").then((c) => c.GuidedToursComponent),
  },
  {
    path: "TourismCompanies",
    canActivate: [
      () => {
        if (!inject(AuthService).isCompanyUser) return true;

        inject(Router).navigate(["/services"]);
        return false;
      },
    ],
    loadComponent: () =>
      import("./components/ticket-forms/tourism-companies/tourism-companies.component").then((c) => c.TourismCompaniesComponent),
  },
  {
    path: "OfficialInstitutions",
    canActivate: [
      () => {
        if (inject(AuthService).isOfficialInstitutionUser || inject(AuthService).isAdmin) return true;

        inject(Router).navigate(["/services"]);
        return false;
      },
    ],
    loadComponent: () =>
      import("./components/ticket-forms/official-institutions/official-institutions.component").then(
        (c) => c.OfficialInstitutionsComponent,
      ),
  },
  {
    path: "FriendsOfOmanMuseum",
    loadComponent: () =>
      import("./components/ticket-forms/friends-of-oman-museum/friends-of-oman-museum.component").then(
        (c) => c.FriendsOfOmanMuseumComponent,
      ),
  },
  {
    path: "Products",
    children: productRoutes,
  },
  {
    path: "FriendsOfOmanMuseum",
    loadComponent: () =>
      import("./components/ticket-forms/friends-of-oman-museum/friends-of-oman-museum.component").then(
        (c) => c.FriendsOfOmanMuseumComponent,
      ),
  },
];

/**
 * @description children routes of the event services
 */
const childrenEventBookRoutes: EventServiceRoutes[] = [
  {
    path: "ExhibitionsAndEvents",
    children: [
      {
        path: ":id",
        loadComponent: () =>
          import("./components/ticket-forms/exhibitions-and-events/exhibitions-and-events.component").then(
            (c) => c.ExhibitionsAndEventsComponent,
          ),
      },
    ],
  },
];

/**
 * @description main services categories routes.
 */
export const bookRoutes: ParentServiceRoutes[] = [
  {
    path: "TOUR",
    loadComponent: () => import("./pages/book/book.component").then((c) => c.BookComponent),
    children: childrenTourBookRoutes,
  },
  {
    path: "EVENT",
    loadComponent: () => import("./pages/book/book.component").then((c) => c.BookComponent),
    children: childrenEventBookRoutes,
  },
];
