<a
  class="service cursor-pointer d-flex flex-column h-100 {{ imageDisplaying ? 'text-white' : 'text-primary padding bg-white' }}"
  #serviceCard
  (click)="openModal()"
>
  @if (!imageDisplaying) {
    <div class="icon d-flex align-items-center h-50">
      <svg>
        <use width="100%" height="100%" [attr.xlink:href]="'assets/images/services-icons/' + service.code + '.svg#' + service.code"></use>
      </svg>
    </div>
  }

  <div class="title d-flex {{ imageDisplaying ? 'overlay h-100' : 'align-items-end h-50' }}">
    <div class="d-flex align-items-center w-100 {{ imageDisplaying ? 'justify-content-center' : 'justify-content-between' }}">
      <h3 class="m-0 text-center font-18 {{ titleClass }}">{{ service | localizedName }}</h3>

      @if (!imageDisplaying) {
        <em class="db-icon-arrow-right text-primary d-flex font-20"></em>
      }
    </div>
  </div>
</a>

<ng-template #loginModal>
  <div class="modal-container login-modal section-bg d-flex flex-column">
    <div class="modal-header bg-header border-0" mat-dialog-header>
      <h1 class="m-0 p-0 pt-3 font-20 text-primary text-center w-100" mat-dialog-title>
        @if (isValidForLoggedUsersOnly) {
          {{ "ACTIONS.LOGIN_FOR_VISIT" | translate }}
        } @else {
          {{ "ACTIONS.CONTINUE_AS" | translate }}
        }
      </h1>
    </div>

    <div class="modal-footer w-100 d-flex justify-content-center gap-4 border-0 mt-2 p-0 pb-4" mat-dialog-actions>
      @if (!isValidForLoggedUsersOnly) {
        <app-button btnLabel="ACTIONS.GUEST" btnClass="px-4" color="primary" (onClick)="loginAsGuest()" />
      }

      <app-button btnLabel="ACTIONS.LOGIN" [btnClass]="isValidForLoggedUsersOnly ? 'px-5' : 'px-4'" color="primary" (onClick)="login()" />
    </div>
  </div>
</ng-template>
