import { UserRole } from "src/app/core";

const Events = [UserRole.EVENTAPPROVER, UserRole.EVENTCREATOR, UserRole.EDUCATIONALEVENTAPPROVER, UserRole.EDUCATIONALEVENTCREATOR];

const ReportsRoles = [UserRole.STATISTICSDEPARTMENT, UserRole.FINANCEDEPARTMENT];

export const Admin = [
  UserRole.ADMIN,
  UserRole.SUPERADMIN,
  UserRole.EVENTCREATOR,
  UserRole.EVENTAPPROVER,
  UserRole.EDUCATIONALEVENTCREATOR,
  UserRole.EDUCATIONALEVENTAPPROVER,
  UserRole.COUNTER,
  UserRole.VISITORSERVICE,
  UserRole.LEARNINGCENTER,
  UserRole.MUSEUMFRIENDSDEPARTMENT,
  UserRole.SECURITY,
  ...ReportsRoles,
];

const ServicesManagement = [UserRole.ADMIN, UserRole.COUNTER, UserRole.VISITORSERVICE];

const Calendar = [
  UserRole.ADMIN,
  UserRole.COUNTER,
  UserRole.MUSEUMFRIENDSDEPARTMENT,
  UserRole.LEARNINGCENTER,
  UserRole.VISITORSERVICE,
  ...Events,
];

const Dashboard = [
  UserRole.ADMIN,
  UserRole.COUNTER,
  UserRole.MUSEUMFRIENDSDEPARTMENT,
  UserRole.LEARNINGCENTER,
  UserRole.VISITORSERVICE,
  ...ReportsRoles,
  ...Events,
];

const Reports = [
  UserRole.ADMIN,
  UserRole.COUNTER,
  UserRole.MUSEUMFRIENDSDEPARTMENT,
  UserRole.LEARNINGCENTER,
  UserRole.VISITORSERVICE,
  ...ReportsRoles,
];

export const Permissions = {
  // Kiosk: [UserRole.KIOSK],
  Home: [UserRole.CUSTOMER],
  Services: [UserRole.CUSTOMER, UserRole.COMPANY, UserRole.GUEST],
  MyTickets: [UserRole.CUSTOMER, UserRole.COMPANY],
  EvaluationsInterests: [UserRole.CUSTOMER],
  Dashboard: [UserRole.ADMIN, UserRole.VISITORSERVICE],
  Admin,
  Calendar,
  UsersManagement: [UserRole.ADMIN],
  Events: [UserRole.ADMIN, UserRole.LEARNINGCENTER, ...Events],
  EventTypes: [UserRole.ADMIN],
  Memberships: [UserRole.ADMIN, UserRole.MUSEUMFRIENDSDEPARTMENT],
  Audit: [UserRole.ADMIN, UserRole.VISITORSERVICE],
  BookingServices: [UserRole.ADMIN, UserRole.COUNTER, UserRole.VISITORSERVICE],
  Reservations: [UserRole.ADMIN, UserRole.COUNTER, UserRole.VISITORSERVICE],

  ServicesManagement,
  RequestsManagement: [UserRole.ADMIN, UserRole.VISITORSERVICE],
  LearningCenter: [UserRole.ADMIN, UserRole.LEARNINGCENTER],

  SpecialTickets: [UserRole.ADMIN, UserRole.COUNTER],
  Nationalities: [UserRole.ADMIN],
  Gates: [UserRole.ADMIN],

  Packages: [...ServicesManagement],
  GuidedTours: [...ServicesManagement],
  Product: [...ServicesManagement],
  VisitorPrices: [...ServicesManagement],
  Museums: [...ServicesManagement],
  GroupOffers: [...ServicesManagement],
  Halls: [...ServicesManagement],
  AudioDevices: [...ServicesManagement],
  Coupons: [...ServicesManagement],

  Reports,
};
