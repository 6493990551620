@if (loginForm) {
  <div class="mt-4 animate row flex-column align-items-center justify-content-center w-100">
    <div class="col-8">
      <form class="d-block login" [formGroup]="loginForm" novalidate (ngSubmit)="login()">
        <app-input-text
          formControlName="username"
          contentType="email"
          appearance="fill"
          matErrorClass="text-secondary"
          label="FIELDS.EMAIL_PHONE"
        >
          <em class="db-icon-user font-24 opacity-50" matPrefix></em>
        </app-input-text>

        <app-input-text
          formControlName="password"
          contentType="password"
          appearance="fill"
          matErrorClass="text-secondary"
          label="FIELDS.PASSWORD"
        >
          <em class="db-icon-lock font-24 opacity-50" matPrefix></em>
        </app-input-text>

        <div class="d-flex justify-content-start mb-4">
          <a [routerLink]="['/auth/forget-password']">
            <span class="cursor-pointer text-secondary font-14">
              {{ "AUTH.FORGET_YOUR_PASSWORD" | translate }}
            </span>
          </a>
        </div>

        <div class="form-actions w-100 mt-4">
          <app-button
            class="w-100 d-block"
            btnClass="w-100 font-18 primary h-50-px px-4"
            btnLabel="ACTIONS.LOGIN"
            color="accent"
            type="submit"
            [disabled]="loginForm.invalid"
          />
        </div>
      </form>
    </div>
  </div>
}
