import { Routes } from "@angular/router";
import { financialReportsRoutes, reportResolver } from "./nestedFeatures/financial-reports";

export const reportsRoutes: Routes = [
  {
    path: "",
    title: "REPORTS.TITLE",
    resolve: {
      tabs: reportResolver,
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),
    children: financialReportsRoutes,
  },
];
