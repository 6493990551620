import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";

import { UserRole } from "../../enums";
import { HttpService } from "../../services";

export const roleGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const http = inject(HttpService);

  const roleCode = http.convertTokenJWT()?.RoleCode;
  if (!roleCode) {
    localStorage.clear();
    router.navigate(["/"]);
    return false;
  }

  let permissionsRoute = route;
  let permissions: UserRole[] = [];

  while (permissionsRoute) {
    if (permissionsRoute.data["permissions"]) {
      permissions = permissionsRoute.data["permissions"];
      break;
    }

    permissionsRoute = permissionsRoute.parent!;
  }

  if (permissions.includes(roleCode)) return true;

  if (!permissions) {
    router.navigateByUrl("/error/403");
    throw new Error("There's no permissions");
  }

  if ([UserRole.COMPANY, UserRole.CUSTOMER].includes(roleCode)) {
    router.navigate(["/services"]);
    return true;
  } else if (roleCode.includes(UserRole.KIOSK)) {
    router.navigate(["/kiosk"]);
    return true;
  } else {
    // permission of the `services` route.
    if (permissions.includes(UserRole.CUSTOMER)) {
      router.navigate(["/admin"]);
      return true;
    }
  }

  router.navigateByUrl("/error/403");
  return false;
};
