import { Routes } from "@angular/router";

export const financialReportsRoutes: Routes = [
  { path: "", redirectTo: "statistical", pathMatch: "full" },
  {
    path: "financial",
    loadComponent: () => import("./pages/financial-report/financial-report.component").then((m) => m.FinancialReportComponent),
  },
  {
    path: "statistical",
    loadComponent: () => import("./pages/financial-report/financial-report.component").then((m) => m.FinancialReportComponent),
  },
];
