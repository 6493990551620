import { Router } from "@angular/router";
import { toSignal } from "@angular/core/rxjs-interop";
import { TranslateModule } from "@ngx-translate/core";
import { Component, ViewEncapsulation, effect, inject } from "@angular/core";

import { LookupsService, Service } from "src/app/core";
import { BreakpointCheckerDirective, ServiceCardComponent } from "src/app/shared";

@Component({
  selector: "app-services",
  standalone: true,
  imports: [TranslateModule, ServiceCardComponent, BreakpointCheckerDirective],
  templateUrl: "./services.component.html",
  styleUrl: "./services.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class ServicesComponent {
  private router = inject(Router);
  private lookupsService = inject(LookupsService);
  private servicesAsSignals = toSignal(this.lookupsService.getReservationServices());

  services: Service[] = [];
  sortedServices: Service[][] = [];

  breakpoints = ["(min-width: 960px)"];
  isNormalScreen: boolean = false;
  private servicesEffect = effect(() => {
    const services = this.servicesAsSignals();
    if (!services) return;

    this.sortedServices = [services.slice(0, 4), services.slice(4, 7), services.slice(7)]; // isNormalScreen
    this.services = services;
  });

  getBreakpointsStates(layoutStates: Record<string, boolean> | undefined) {
    this.isNormalScreen = layoutStates!["(min-width: 960px)"];
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(["/"]);
  }
}
