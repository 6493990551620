import { Routes } from "@angular/router";

export const exhibitionsAndEventsRoutes: Routes = [
  {
    path: "",
    title: "SERVICES.ExhibitionsAndEvents",
    loadComponent: () =>
      import("./pages/exhibitions-and-events/exhibitions-and-events.component").then((m) => m.ExhibitionsAndEventsComponent),
  },
  {
    path: ":id",
    title: "SERVICES.ExhibitionsAndEvents",
    loadComponent: () =>
      import("./pages/exhibitions-and-events-details/exhibitions-and-events-details.component").then(
        (m) => m.ExhibitionsAndEventsDetailsComponent,
      ),
  },
];
