<div class="mt-4 animate row flex-column align-items-center justify-content-center w-100">
  <div class="col-8">
    <form class="register-form d-block mt-4 animate mb-3" [formGroup]="registerForm" (ngSubmit)="register()">
      <app-input-text formControlName="nameEn" appearance="fill" label="FIELDS.NAME_EN" matErrorClass="text-secondary">
        <em class="db-icon-user font-24 opacity-50" matPrefix></em>
      </app-input-text>

      <app-input-text formControlName="nameAr" appearance="fill" label="FIELDS.NAME_AR" matErrorClass="text-secondary">
        <em class="db-icon-user font-24 opacity-50" matPrefix></em>
      </app-input-text>

      <app-input-text
        formControlName="email"
        contentType="email"
        appearance="fill"
        label="FIELDS.EMAIL"
        placeholder="xyz@domain.com"
        matErrorClass="text-secondary"
      >
        <em class="db-icon-email font-24 opacity-50" matPrefix></em>
      </app-input-text>

      <app-input-text formControlName="phone" contentType="number" appearance="fill" label="FIELDS.PHONE" matErrorClass="text-secondary">
        <em class="db-icon-phone font-24 opacity-50" matPrefix></em>
      </app-input-text>

      <app-datepicker
        formControlName="dateOfBirth"
        appearance="fill"
        label="FIELDS.DATE_OF_BIRTH"
        matErrorClass="text-secondary"
        [maxDate]="today"
      />

      <app-dropdown
        label="FIELDS.GENDER"
        formControlName="gender"
        appearance="fill"
        matErrorClass="text-secondary"
        [options]="genders()!"
      />

      <app-dropdown
        label="FIELDS.COUNTRY"
        formControlName="countryId"
        appearance="fill"
        matErrorClass="text-secondary"
        [options]="countries()!"
        (selected)="getGovernorates($event)"
      />

      <app-dropdown
        label="FIELDS.GOVERNORATE"
        formControlName="governorateId"
        appearance="fill"
        matErrorClass="text-secondary"
        [options]="governorates()!"
        (selected)="getStates($event)"
      />

      <app-dropdown label="FIELDS.STATE" formControlName="stateId" appearance="fill" matErrorClass="text-secondary" [options]="states()!" />

      <app-input-text
        formControlName="password"
        [contentType]="showPassword ? 'text' : 'password'"
        appearance="fill"
        matErrorClass="text-secondary"
        label="FIELDS.PASSWORD"
      >
        <em class="db-icon-lock font-24 opacity-50" matPrefix></em>
        <em class="db-icon-{{ showPassword ? 'eye-off' : 'eye' }} font-24 opacity-50" (click)="showPassword = !showPassword" matSuffix></em>
      </app-input-text>

      <app-input-text
        formControlName="confirmPassword"
        [contentType]="showPassword ? 'text' : 'password'"
        appearance="fill"
        matErrorClass="text-secondary"
        label="FIELDS.CONFIRMPASSWORD"
      >
        <em class="db-icon-lock font-24 opacity-50" matPrefix></em>
        <em class="db-icon-{{ showPassword ? 'eye-off' : 'eye' }} font-24 opacity-50" (click)="showPassword = !showPassword" matSuffix></em>
      </app-input-text>

      <app-checkbox formControlName="isOmanResident" label="FIELDS.IS_OMAN_RESIDENT" />
      <app-checkbox formControlName="isStudent" label="FIELDS.IS_STUDENT" />

      <div class="d-flex align-items-center justify-content-center w-100 mt-4">
        <app-button
          class="w-50"
          btnClass="font-18 primary h-50-px"
          btnLabel="ACTIONS.REGISTER"
          color="accent"
          [type]="'submit'"
          [disabled]="registerForm.invalid"
        />
      </div>
    </form>
  </div>
</div>
