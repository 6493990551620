import { Routes } from "@angular/router";

export const myTicketsRoutes: Routes = [
  {
    path: "",
    data: {
      customClass: "page-wrapper py-0 mt-4",
      tabs: [
        {
          key: "MyReservations",
          title: "TICKETS.MY_RESERVATIONS",
          routerLink: "/my-tickets",
        },
        {
          key: "MyCompanyRequest",
          title: "COMPANIES.MY_COMPANY_REQUEST",
          routerLink: "my-company-request",
        },
        {
          key: "MyMembershipRequest",
          title: "FRIENDS_OF_OMAN_MUSEUM.MEMBERSHIP_REQUEST",
          routerLink: "my-membership-request",
        },
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),
    children: [
      {
        path: "",
        title: (_, state) => {
          return state.url.includes("admin") ? "TICKETS.RESERVATIONS" : "TICKETS.MY_RESERVATIONS";
        },
        loadComponent: () => import("./pages/my-tickets/my-tickets.component").then((c) => c.MyTicketsComponent),
      },
      {
        path: "my-company-request",
        title: "COMPANIES.MY_COMPANY_REQUEST",
        loadComponent: () => import("./pages/my-company-request/my-company-request.component").then((c) => c.MyCompanyRequestComponent),
      },
      {
        path: "my-membership-request",
        title: "FRIENDS_OF_OMAN_MUSEUM.MEMBERSHIP_REQUEST",
        loadComponent: () =>
          import("./pages/my-membership-request/my-membership-request.component").then((c) => c.MyMembershipRequestComponent),
      },
    ],
  },
  {
    path: ":reservationNumber",
    loadComponent: () => import("src/app/shared/components/ticket/ticket.component").then((c) => c.TicketComponent),
  },
];
