import { Directive, effect, ElementRef, inject, input } from "@angular/core";

import { AuthService, UserRole } from "src/app/core";

@Directive({
  selector: "[permissions]",
  standalone: true,
})
export class ElementPermissionsDirective {
  private authService = inject(AuthService);
  private element: ElementRef<HTMLElement> = inject(ElementRef);

  permissions = input.required<UserRole[]>({ alias: "permissions" });

  constructor() {
    effect(() => {
      if (!this.authService.userRole) return;

      const hasPermission = this.permissions().includes(this.authService.userRole);

      if (!hasPermission) this.element.nativeElement.remove();
    });
  }
}
