import { toSignal } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ControlContainer, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Component, inject, Injector, runInInjectionContext, Signal, signal } from "@angular/core";

import { Dropdown, LookupsService } from "src/app/core";
import { InputTextComponent, DropdownComponent } from "src/app/shared";

const SHARED = [InputTextComponent, DropdownComponent];

@Component({
  selector: "app-company-details",
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, ...SHARED],
  templateUrl: "./company-details.component.html",
  styleUrl: "./company-details.component.scss",
})
export class CompanyDetailsComponent {
  private injector = inject(Injector);
  private lookupsService = inject(LookupsService);
  private activatedRoute = inject(ActivatedRoute);
  private controlContainer = inject(ControlContainer);

  itemId = this.activatedRoute.snapshot.paramMap.get("id");
  countries = toSignal(this.lookupsService.getCountries());
  governorates: Signal<Dropdown[] | undefined> = signal([]);
  states: Signal<Dropdown[] | undefined> = signal([]);

  get form() {
    return this.controlContainer.control as FormGroup;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    if (this.itemId) {
      this.getGovernorates(this.form.get("membershipCompanyDetails")?.get("countryId")?.value!);
      this.getStates(this.form.get("membershipCompanyDetails")?.get("governorateId")?.value!);
    }
  }

  getGovernorates(id: number) {
    runInInjectionContext(this.injector, () => (this.governorates = toSignal(this.lookupsService.getGovernorates({ id }))));
  }

  getStates(id: number) {
    runInInjectionContext(this.injector, () => (this.states = toSignal(this.lookupsService.getStates({ id }))));
  }
}
