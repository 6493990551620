<div class="auth-wrapper d-flex flex-column bg-page w-100 h-100 {{ pageType.toLowerCase() }}">
  <app-auth-header />

  <div class="d-flex flex-column flex-lg-row overflow-x-hidden h-100">
    <div class="auth-content d-none d-lg-flex flex-column overflow-hidden">
      <div class="content w-100 position-relative">
        <img class="img-fluid image w-100 h-100" src="assets/images/landing/auth.png" alt="Login Background" />
      </div>
    </div>

    <div class="forms-wrapper page-wrapper d-flex flex-column h-100">
      <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center justify-content-sm-end">
        <p class="text-secondary align-self-sm-end m-0 mb-3 font-10 d-flex align-items-center gap-1">
          {{ "AUTH." + pageType + "_HINT" | translate }}

          <a [routerLink]="isSignIn ? ['/auth/register'] : ['/auth/login']">
            <app-button
              color="primary"
              btnClass="without-shadow text-white fw-bold font-16 px-2"
              [btnLabel]="isSignIn ? 'AUTH.CREATE_ACCOUNT' : 'AUTH.LOGIN'"
            />
          </a>
        </p>
      </div>

      <div class="forms-content d-flex flex-column gap-3 h-100" [class.justify-content-center]="isSignIn">
        <div class="d-flex flex-column align-items-center justify-content-center animate gap-3">
          <h6 class="m-0 font-24 text-secondary">{{ "AUTH." + pageType | translate }}</h6>
        </div>

        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
