<div class="form-group">
  @if (label) {
    <label class="font-11 text-primary mb-2 d-flex align-items-center" [for]="formControlName">{{ label | translate }}</label>
  }

  <div class="quantity-wrapper d-flex align-items-center" [id]="formControlName">
    <app-button
      btnClass="btn-count without-shadow primary border-radius-0"
      [disabled]="(!value && !count) || disableMinus"
      (onClick)="minus()"
    >
      <em class="db-icon-minus font-18"></em>
    </app-button>

    @if (withInput) {
      <input
        type="number"
        class="quantity-input form-control border-radius-0 text-primary"
        [id]="formControlName"
        [formControl]="control"
        [readonly]="disabled"
        (input)="inputValue.emit(value); control ? (count = value) : null"
        (keypress)="onKeyPress($event)"
        [value]="value ?? count"
        [min]="min"
        [max]="max"
      />
    } @else {
      <span class="quantity text-middle m-0 p-0 d-flex align-items-center justify-content-center font-16" [id]="formControlName">
        {{ value ?? count }}
      </span>
    }

    <app-button btnClass="btn-count without-shadow primary border-radius-0" [disabled]="disablePlus" (onClick)="plus()">
      <em class="db-icon-plus text-primary font-18"></em>
    </app-button>
  </div>

  @if (control && control.errors && control.dirty) {
    <mat-error>
      <span class="font-12">
        {{ control.errors | validationHandler }}
      </span>
    </mat-error>
  }
</div>
