import { Routes } from "@angular/router";

export const targetedMessagesListRoutes: Routes = [
  {
    path: "",
    title: "FRIENDS_OF_OMAN_MUSEUM.TARGETED_MESSAGES",
    loadComponent: () =>
      import("./pages/targeted-messages-list/targeted-messages-list.component").then((m) => m.TargetedMessagesListComponent),
  },
];
