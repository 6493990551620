import { Routes } from "@angular/router";

import { sendInvitationListRoutes } from "./nested-features/send-invitation";
import { targetedMessagesListRoutes } from "./nested-features/targeted-messages";
import { membershipsListRoutes, membershipsRoutes } from "./nested-features/memberships";

export const friendsOfOmanRoutes: Routes = [
  {
    path: "",
    title: "FRIENDS_OF_OMAN_MUSEUM.DEPARTMENT_PRIVILEGES",
    data: {
      tabs: [
        {
          key: "Memberships",
          title: "FRIENDS_OF_OMAN_MUSEUM.MEMBERSHIPS",
          routerLink: "memberships",
        },
        {
          key: "SendInvitation",
          title: "FRIENDS_OF_OMAN_MUSEUM.SEND_INVITATION",
          routerLink: "send-invitation",
        },
        {
          key: "TargetedMessages",
          title: "FRIENDS_OF_OMAN_MUSEUM.TARGETED_MESSAGES",
          routerLink: "targeted-messages",
        },
        {
          key: "SendEvaluationForm",
          title: "FRIENDS_OF_OMAN_MUSEUM.SEND_EVALUATION_FORM",
          routerLink: "send-evaluation-form",
        },
        {
          key: "MembershipDashboard",
          title: "FRIENDS_OF_OMAN_MUSEUM.MEMBERSHIP_DASHBOARD",
          routerLink: "membership-dashboard",
        }
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),
    children: [
      { path: "", redirectTo: "memberships", pathMatch: "full" },
      {
        path: "memberships",
        children: membershipsListRoutes,
      },
      {
        path: "send-invitation",
        children: sendInvitationListRoutes,
      },
      {
        path: "targeted-messages",
        children: targetedMessagesListRoutes,
      },
      {
        path: "send-evaluation-form",
        loadComponent: () =>
          import("./nested-features/send-evaluation-form/send-evaluation-form.component").then((c) => c.SendEvaluationFormComponent),
      },
      {
        path: "membership-dashboard",
        loadComponent: () =>
          import("./nested-features/dashboard/pages/membership-friends-dashboard/membership-friends-dashboard.component").then((c) => c.MembershipFriendsDashboardComponent),
      }
      
    ],
  },
  {
    path: "memberships",
    children: membershipsRoutes,
  }
];
