<main class="d-flex" style="width: 100%;">
  @if (!isGuest) {
    <app-sidebar></app-sidebar>
  }

  <section class="layout-wrapper d-flex flex-column flex-grow-1" [class.guest]="isGuest" [style.width]="(isSidebarOpened$ | async) ? 'calc(100% - 250px)' : '100%'">
    <app-navbar></app-navbar>

    <section class="main-page d-flex flex-column flex-grow-1 position-relative" style="width: 100%;">
      <router-outlet></router-outlet>
    </section>
  </section>
</main>
