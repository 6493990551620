<mat-toolbar [class.page-padding]="isOverview || isGuest" (isEnglish)="isEnglish = $event">
  @if (!isGuest && !isOverview) {
    <app-button btnClass="without-shadow" (onClick)="sidebarToggle()">
      <em class="db-icon-bar-3 font-22 text-primary"></em>
    </app-button>
  }

  <img
    class="mx-3 cursor-pointer"
    src="assets/images/logo-with-text.png"
    alt="Logo Photo"
    [width]="isMobileScreen ? 60 : 120"
    routerLink="/"
  />

  <span class="flex-grow-1"></span>

  <mat-list class="d-flex align-items-center p-0" [class.gap-3]="isOverview" role="list">
    @if (user && !isGuest) {
      <!-- user details -->
      <mat-list-item class="p-0 overflow-visible me-sm-3" role="listitem">
        <app-menu
          class="user-menu"
          [btnLabel]="''"
          [btnClass]="isLargeScreen ? '' : 'without-shadow'"
          [menuItems]="userMenuItems"
          [menuTemplateRef]="menuTemplateRef"
          [menuItemTemplateRef]="menuItemTemplateRef"
        >
          <ng-template #menuTemplateRef>
            <div class="user-info-wrapper d-flex align-items-center gap-3 cursor-pointer">
              <div class="d-flex align-items-center gap-3 user-info">
                <!-- user photo-->
                <app-button btnClass="header-icon without-shadow d-none d-sm-block">
                  <img
                    class="border-radius-50"
                    src="assets/images/avatar-profile-avatar--placeholder.jpg"
                    width="32px"
                    height="32px"
                    alt="user-photo"
                  />
                </app-button>

                <span class="font-14 text-primary">
                  {{ "HELLO" | translate: { userName: user.NameEn } }}
                </span>
              </div>

              <svg
                class="chevron-down text-primary"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>
            </div>
          </ng-template>

          <ng-template #menuItemTemplateRef let-menuItem>
            <span class="d-flex justify-content-center align-items-center py-2 font-14">
              {{ menuItem.label | translate }}
            </span>
          </ng-template>
        </app-menu>
      </mat-list-item>
    }

    <!-- notifications -->
    <!-- @if (!isOverview && !isGuest) {
      <mat-list-item class="p-0 overflow-visible" role="listitem">
        <app-menu
          [btnLabel]="isLargeScreen ? 'NOTIFICATIONS' : ''"
          [btnClass]="isLargeScreen ? '' : 'without-shadow'"
          [menuItems]="$any(notifications)"
          [menuItemTemplateRef]="menuItemTemplateRef"
        >
          <em class="db-icon-notifications font-18"></em>

          <ng-template #menuItemTemplateRef let-notification>
            @if (notifications.length) {
              <div class="d-flex justify-content-center align-items-center px-3 py-2" (click)="setIsReadAndOpenModal(notification)">
                @if (!notification.isRead) {
                  <span>
                    <i class="db-icon-star text-danger mx-3"></i>
                  </span>
                }

                <span>
                  {{ notification.titleAr }}
                </span>
              </div>
            } @else {
              <span class="d-flex justify-content-center align-items-center px-3 py-2">
                {{ "EMPTY.NO_NOTIFICATIONS" | translate }}
              </span>
            }
          </ng-template>
        </app-menu>
      </mat-list-item>
    } -->

    <!-- changeLanguage -->
    <mat-list-item class="p-0 overflow-visible me-sm-4" role="listitem">
      <app-button
        [btnLabel]="isLargeScreen ? 'app_language' : ''"
        btnClass="without-shadow font-toggle"
        iconPosition="left"
        (onClick)="changeLanguage()"
      >
        <em class="db-icon-world text-primary font-18"></em>
      </app-button>
    </mat-list-item>

    <!-- logOut -->
    @if (isOverview || isGuest) {
      <mat-list-item class="p-0 overflow-visible" role="listitem">
        @if (isOverview) {
          <a [routerLink]="['/auth']">
            <app-button btnLabel="AUTH.LOGIN" btnClass="without-shadow font-14 h-40 px-4" color="primary" />
          </a>
        } @else {
          <app-button
            [btnLabel]="isLargeScreen ? 'AUTH.LOGIN' : ''"
            btnClass="without-shadow font-14 h-40 px-4"
            iconPosition="left"
            (onClick)="logOut()"
          />
        }
      </mat-list-item>
    }
  </mat-list>
</mat-toolbar>
