<div class="mt-4 animate row flex-column align-items-center justify-content-center w-100">
  <div class="col-8">
    @if (forgetPasswordTabs.length) {
      <app-tabs class="w-100" [tabs]="forgetPasswordTabs" (selectedIndexChange)="getActiveTab($event)" />
    }

    <div class="form-actions w-100 mt-4">
      <app-button
        class="mt-3 d-block"
        btnClass=" font-18 primary h-50-px px-4"
        color="accent"
        [type]="'submit'"
        [btnLabel]="'ACTIONS.CONTINUE'"
        [disabled]="forgetPasswordForm.invalid"
        (onClick)="forget()"
      />

      <a [routerLink]="['/auth/login']">
        <app-button class="mt-3 d-block" btnClass="font-18 without-shadow secondary h-50-px" btnLabel="ACTIONS.CANCEL" color="primary" />
      </a>
    </div>
  </div>
</div>

<ng-template #byEmailTab>
  <form class="d-block" [formGroup]="forgetPasswordForm" (ngSubmit)="forget()">
    <app-input-text formControlName="email" placeholder="xyz@domain.com" appearance="fill" contentType="email" label="FIELDS.EMAIL">
      <em class="db-icon-phone font-24 opacity-50" matPrefix></em>
    </app-input-text>
  </form>
</ng-template>

<ng-template #byPhoneTab>
  <form class="d-block" [formGroup]="forgetPasswordForm" (ngSubmit)="forget()">
    <app-input-text formControlName="phone" appearance="fill" contentType="number" label="FIELDS.PHONE">
      <em class="db-icon-phone font-24 opacity-50" matPrefix></em>
    </app-input-text>
  </form>
</ng-template>
