import { Routes } from "@angular/router";

export const dashboardRoutes: Routes = [
  {
    path: "",
    title: "DASHBOARD.TITLE",
    data: {
      tabs: [
        {
          key: "booking",
          title: "DASHBOARD.BOOKING",
          routerLink: "booking",
        },
        {
          key: "approvedRequestes",
          title: "DASHBOARD.APPROVED_REQUESTS",
          routerLink: "approved-requests",
        },
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),

    children: [
      {
        path: "",
        redirectTo: "booking",
        pathMatch: "full",
      },
      {
        path: "booking",
        title: "DASHBOARD.BOOKING",
        loadComponent: () => import("./pages/booking/booking.component").then((c) => c.BookingComponent),
      },
      {
        path: "approved-requests",
        title: "DASHBOARD.APPROVED_REQUESTS",
        loadComponent: () => import("./pages/approved-requests/approved-requests.component").then((c) => c.ApprovedRequestsComponent),
      },
    ],
  },
];
