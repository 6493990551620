import { Routes } from "@angular/router";

import { reportsRoutes } from "./pages/reports";
import { servicesRoutes } from "../services";
import { dashboardRoutes } from "./pages/dashboard";
import { eventsListRoutes } from "./pages/exhibitions-and-events";
import { reservationsRoutes } from "./pages/reservations";
import { friendsOfOmanRoutes } from "./pages/friends-of-oman-privilages";
import { eventsCalendarRoutes } from "./pages/events-calender";
import { usersManagementRoutes } from "./pages/users-management";
import { servicesManagementRoutes } from "./pages/services-management";
import { auditMonitorsManagementRoutes } from "./pages/audit-monitors";
import { learningCenterManagementRoutes } from "./pages/learning-center";
import { requestsManagementRoutes, schoolUniversityVisitsRoute } from "./pages/requests-management";

import { Permissions } from "src/assets/config/config";

export const adminRoutes: Routes = [
  {
    path: "",
    loadComponent: () => import("./pages/admin/admin.component").then((c) => c.AdminComponent),
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      {
        path: "dashboard",
        data: { permissions: Permissions.Dashboard },
        children: dashboardRoutes,
      },
      {
        path: "booking-services",
        data: { permissions: Permissions.BookingServices },
        children: servicesRoutes,
      },
      {
        path: "users-management",
        data: { permissions: Permissions.UsersManagement },
        children: usersManagementRoutes,
      },
      {
        path: "services-management",
        data: { permissions: Permissions.ServicesManagement },
        children: servicesManagementRoutes,
      },
      {
        path: "reservations",
        data: { permissions: Permissions.Reservations },
        children: reservationsRoutes,
      },
      {
        path: "requests-management",
        data: { permissions: Permissions.RequestsManagement },
        children: requestsManagementRoutes,
      },
      {
        path: "friends-of-oman-privilages",
        data: { permissions: Permissions.Memberships },
        children: friendsOfOmanRoutes,
      },
      {
        path: "exhibitions-events",
        data: { permissions: Permissions.Events },
        children: eventsListRoutes,
      },
      {
        path: schoolUniversityVisitsRoute.path,
        title: "REQUESTS.SCHOOL_UNIVERSITY_REQUESTS",
        loadComponent: () =>
          import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
            (c) => c.BaseTabsManagementComponent,
          ),
        children: [
          {
            ...schoolUniversityVisitsRoute,
            path: "",
            data: { ...schoolUniversityVisitsRoute.data, permissions: Permissions.LearningCenter },
          },
        ],
      },
      {
        path: "audit-monitors",
        data: { permissions: Permissions.Audit },
        children: auditMonitorsManagementRoutes,
      },
      {
        path: "reports",
        data: { permissions: Permissions.Reports },
        children: reportsRoutes,
      },
      {
        path: "learning-center",
        data: { permissions: Permissions.LearningCenter },
        children: learningCenterManagementRoutes,
      },
      {
        path: "calendar",
        data: { permissions: Permissions.Calendar },
        children: eventsCalendarRoutes,
      },
    ],
  },
];
