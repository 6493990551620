import { MatError } from "@angular/material/form-field";
import { toSignal } from "@angular/core/rxjs-interop";
import { TranslateModule } from "@ngx-translate/core";
import { NG_VALUE_ACCESSOR, ValidationErrors, Validators } from "@angular/forms";
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
  effect,
  forwardRef,
  inject,
  runInInjectionContext,
} from "@angular/core";

import { ValidationHandlerPipe } from "../../pipes";
import { ReactiveFormsBaseComponent } from "../../base-components";

import { environment } from "src/environments/environment";
import { Attachment, AttachmentResponse, AttachmentService } from "src/app/core";

interface File {
  fileName: string;
  size: string;
  attachmentDisplaySize: string;
  url: string;
  extension: string;
  isSuccess: boolean;
  isPublic: boolean;
  fileId: string;
  fileUrl: string;
}

@Component({
  selector: "app-attachment",
  standalone: true,
  imports: [ValidationHandlerPipe, MatError, TranslateModule],
  templateUrl: "./attachment.component.html",
  styleUrl: "./attachment.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AttachmentComponent),
      multi: true,
    },
  ],
})
export class AttachmentComponent extends ReactiveFormsBaseComponent {
  private injector = inject(Injector);
  private attachmentService = inject(AttachmentService);

  fileToUpload!: any;
  imageExtensions: string[] = ["png", "jpg", "jpeg", "bmp", "tiff", "svg", "gif"];
  videosExtensions: string[] = ["mp4", "mov", "webm", "mkv", "flv", "avi", "wmv"];
  filesExtensions: string[] = [
    "pdf",
    "powerpoint",
    "word",
    "excel",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "xls",
    "xlsx",
    "txt",
    "zip",
    "rar",
    "crt",
    "p7b",
    "html",
    "htm",
    "rtf",
    "msg",
  ];
  fileType!: string;
  acceptedTypes!: string;
  uploadedFiles: any[] = []; // insert all the files which the user choose.
  selectedFiles: File[] = []; // insert all the final files.
  isHidden = false;
  isSuccess = false;
  isFailed!: boolean;
  uploadedFile!: { type: string; maxSize: number };
  downloadUrl = environment.attachmentAppDownload;
  oldFiles: any[] = [];

  deletedFile = null;

  /**
   * Inputs
   */
  @Input() label: string = "Attachment.Attachment";
  @Input() additionalLabel: string = "";
  @Input({ required: true }) type: "image" | "video" | "document" | "imageDocument" | "imageVideo" | "videoDocument" = "image";
  @Input({ required: true }) allowedTypes!: { type: string; maxSize: number }[];
  @Input() maxFilesNumbers = 1;
  @Input() isMultiple = false;
  @Input() isPublic = false;
  @Input() isEditMode = false;
  @Input() isCustom = false;
  @Input() saveUrl = false;
  /**
   * Outputs
   */
  @Output() uploadEvent: EventEmitter<Attachment[]> = new EventEmitter();
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild("file") fileInput!: ElementRef;

  ngOnInit(): void {
    this.setAcceptedTypes();

    runInInjectionContext(this.injector, () => {
      const controlValue = toSignal(this.control.valueChanges);

      effect(() => {
        if (controlValue()?.length) {
          if (this.maxFilesNumbers > 0 && this.isEditMode) {
            this.selectedFiles = [];
            this.oldFiles = [];

            controlValue().forEach((element: AttachmentResponse) => {
              let file = {
                fileName: element.fileName,
                size: element.size,
                attachmentDisplaySize: this.convertSize({ size: element.size }).attachmentDisplaySize,
                url: element.url,
                extension: element.extension,
                isSuccess: true,
                isPublic: element.isPublic,
                fileId: element.fileId,
                fileUrl: element.url,
              };

              this.selectedFiles.push(file);
            });
          }
        } else {
          this.isHidden = false;
        }
      });
    });
  }

  /**
   * Sets the accepted file types based on the component's `type` property.
   *
   * The `type` property can have the following values:
   * - "image": Accepts all image file types.
   * - "video": Accepts all video file types.
   * - "imageVideo": Accepts both image and video file types.
   * - "imageDocument": Accepts image files and various document file types including PDF, DOC, DOCX, PPT, PPTX, XLS, XLSX, TXT, RAR, ZIP, CRT, P7B, HTML, HTM, RTF, and MSG.
   * - "videoDocument": Accepts video files and various document file types including PDF, DOC, DOCX, PPT, PPTX, XLS, XLSX, TXT, RAR, ZIP, CRT, P7B, HTML, HTM, RTF, and MSG.
   * - "document": Accepts various document file types including PDF, DOC, DOCX, PPT, PPTX, XLS, XLSX, TXT, RAR, ZIP, CRT, P7B, HTML, HTM, RTF, and MSG.
   * - Any other value: Accepts all file types.
   *
   * This method updates the `acceptedTypes` property based on the `type` property.
   */
  setAcceptedTypes() {
    if (this.type === "image") {
      this.acceptedTypes = "image/*";
    } else if (this.type === "video") {
      this.acceptedTypes = "video/*";
    } else if (this.type === "imageVideo") {
      this.acceptedTypes = "image/*, video/*";
    } else if (this.type === "imageDocument") {
      this.acceptedTypes =
        "image/*, application/pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .rar, .zip, .crt, .p7b, .html, .htm, .rtf, .msg";
    } else if (this.type === "videoDocument") {
      this.acceptedTypes =
        "video/*, application/pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .rar, .zip, .crt, .p7b, .html, .htm, .rtf, .msg";
    } else if (this.type === "document") {
      this.acceptedTypes = "application/pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .rar, .zip, .crt, .p7b, .html, .htm, .rtf, .msg";
    } else {
      this.acceptedTypes = "*";
    }
  }

  /**
   * Handles the file upload event, processes the uploaded files, and sets the file type based on the file's MIME type or extension.
   *
   * @param event - The event object from the file input element or the file drop event.
   *
   * The method performs the following steps:
   * 1. Checks if the event has a target property and retrieves the files from the event.
   * 2. Sets the uploaded files to the component's `uploadedFiles` property.
   * 3. Verifies if the total number of selected and uploaded files does not exceed the maximum allowed files.
   * 4. Iterates over each uploaded file and determines its type based on the MIME type or file extension.
   * 5. Calls the `fileHandling` method for each file to handle the file processing.
   * 6. If the total number of files exceeds the maximum allowed, sets an error state.
   */
  onUploadFile(event: any) {
    if (event.target) {
      const files: any[] = event.target.files;
      this.uploadedFiles = files;
    } else {
      this.uploadedFiles = event;
    }

    if (this.selectedFiles.length + this.uploadedFiles.length <= this.maxFilesNumbers) {
      for (let file of this.uploadedFiles) {
        if (file.type.split("/").pop().toLowerCase() === "svg+xml") {
          this.fileType = "svg";
        } else if (!file.type) {
          // if the type equal 'null'
          if (file.name.split(".").pop().toLowerCase() === "doc" || file.name.split(".").pop().toLowerCase() === "docx") {
            this.fileType = "word";
          } else if (file.name.split(".").pop().toLowerCase() === "ppt" || file.name.split(".").pop().toLowerCase() === "pptx") {
            this.fileType = "powerpoint";
          } else if (file.name.split(".").pop().toLowerCase() === "xls" || file.name.split(".").pop().toLowerCase() === "xlsx") {
            this.fileType = "excel";
          } else if (file.name.split(".").pop().toLowerCase() === "rar") {
            this.fileType = "rar";
          } else if (file.name.split(".").pop().toLowerCase() === "msg") {
            this.fileType = "msg";
          }
        } else if (
          file.type.split("/").pop().toLowerCase() === "vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type.split("/").pop().toLowerCase() === "msword"
        ) {
          this.fileType = "word";
        } else if (
          file.type.split("/").pop().toLowerCase() === "vnd.openxmlformats-officedocument.presentationml.presentation" ||
          file.type.split("/").pop().toLowerCase() === "vnd.ms-powerpoint"
        ) {
          this.fileType = "powerpoint";
        } else if (
          file.type.split("/").pop().toLowerCase() === "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type.split("/").pop().toLowerCase() === "vnd.ms-excel"
        ) {
          this.fileType = "excel";
        } else if (file.type.split("/").pop().toLowerCase() === "plain") {
          this.fileType = "txt";
        } else if (file.type.split("/").pop().toLowerCase() === "x-zip-compressed" || file.type.split("/").pop().toLowerCase() === "gz") {
          this.fileType = "zip";
        } else if (file.type.split("/").pop().toLowerCase() === "x-x509-ca-cert") {
          this.fileType = "crt";
        } else if (file.type.split("/").pop().toLowerCase() === "x-pkcs7-certificates") {
          this.fileType = "p7b";
        } else if (file.type.split("/").pop().toLowerCase() === "jpeg") {
          this.fileType = "jpg";
        } else if (file.type.split("/").pop().toLowerCase() === "html") {
          this.fileType = "html";
        } else if (file.type.split("/").pop().toLowerCase() === "htm") {
          this.fileType = "htm";
        } else {
          this.fileType = file.type.split("/").pop().toLowerCase();
        }
        // console.log(file.type)
        this.fileHandling(file);
      }
    } else {
      this.setError({ maxFiles: { requiredLength: this.maxFilesNumbers } });
    }
  }

  /**
   * Handles the file upload process, including validation of file type and size,
   * reading the file content, and updating the list of selected files.
   *
   * @param file - The file object to be handled.
   *
   * The function performs the following steps:
   * 1. Checks if the file type is allowed.
   * 2. Validates the file size against the maximum allowed size.
   * 3. Checks if the file has already been selected.
   * 4. Reads the file content as a data URL.
   * 5. Converts the file size and updates the file content if necessary.
   * 6. Adds the file to the list of selected files.
   * 7. Sets form control errors if there are pending uploads.
   * 8. Saves the file and hides the file input if the maximum number of files is reached.
   *
   * Errors are set if:
   * - The file type is not allowed.
   * - The file size exceeds the maximum allowed size.
   * - The file has already been selected.
   */
  fileHandling(file: any) {
    const fileType = this.allowedTypes.find((allowedType) => allowedType.type === this.fileType);
    this.uploadedFile = fileType as { type: string; maxSize: number };

    if (fileType) {
      if (file.size <= fileType.maxSize * 1024 * 1024) {
        if (this.selectedFiles.find((selectedFile) => selectedFile.fileName === file.name)) {
          this.setError({ fileSelected: true });
        } else {
          let finalFile: any;
          let reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = () => {
            finalFile = {
              fileName: file.name,
              size: file.size,
              url: reader.result,
              extension: this.fileType,
              isSuccess: false,
              content: "",
            };
          };

          reader.onloadend = () => {
            this.convertSize(finalFile);
            let content = reader.result?.toString();
            if (this.isCustom) {
              finalFile["content"] = content?.substr(content.indexOf(",") + 1);
            }
            this.selectedFiles.push(finalFile);

            if (this.uploadedFiles.length > 1) {
              this.control?.setErrors({ pending: true });
            }

            this.saveFile(this.uploadedFiles);
            if (this.selectedFiles.length === this.maxFilesNumbers) {
              this.isHidden = true;
            }
          };
        }
      } else {
        this.setError({ maxSize: { requiredLength: this.uploadedFile.maxSize } });
      }
    } else {
      this.setError({ allowedTypes: this.allowedTypes.map((each) => each.type) });
    }
  }

  /**
   * Converts the size of a file to a human-readable format and updates the `attachmentDisplaySize` property.
   *
   * @param finalFile - An object containing the size of the file and an optional `attachmentDisplaySize` property.
   * @returns The updated `finalFile` object with the `attachmentDisplaySize` property set to a human-readable format.
   *
   * The function handles the following cases:
   * - If the size is in bytes and greater than 1, it converts the size to KB or MB.
   * - If the size is less than 1 MB and the size is returned from the API, it converts the size to KB.
   * - If the size is greater than 1 MB and the size is returned from the API, it converts the size to MB.
   */
  convertSize(finalFile: { size: any; attachmentDisplaySize?: any }) {
    const fileSize = +finalFile.size;

    if (!fileSize.toString().includes(".") && fileSize > 1) {
      // in case the size with bytes
      if (fileSize <= 1024 * 1024) {
        finalFile["attachmentDisplaySize"] = (fileSize / Math.pow(1024, 1)).toFixed() + " KB";
      } else if (fileSize <= 1024 * 1024 * 1024) {
        finalFile["attachmentDisplaySize"] = (fileSize / Math.pow(1024, 2)).toFixed(1) + " MB";
      }
    } else if (fileSize < 1) {
      // in case the size < 1 MB and the size returns from API - NOTE - if the size returns with MB
      if (fileSize * 1024 <= 1024 * 1024) {
        finalFile["attachmentDisplaySize"] = (fileSize * Math.pow(1024, 1)).toFixed() + " KB";
      }
    } else {
      // in case the size > 1 MB and the size returns from API - NOTE - if the size returns with MB
      finalFile["attachmentDisplaySize"] = fileSize.toFixed(1) + " MB";
    }

    return finalFile;
  }

  /**
   * Sets the validation error on the control, marks it as dirty, and clears the file input value.
   * After a delay of 3 seconds, it clears the validation error.
   *
   * @param error - The validation error to set, or null to clear the error.
   */
  private setError(error: ValidationErrors | null) {
    this.control.setErrors(error);
    this.control.markAsDirty();
    this.fileInput.nativeElement.value = "";

    setTimeout(() => {
      if (this.control.hasValidator(Validators.required)) {
        this.control.setErrors(null);
        this.control.updateValueAndValidity();
      }
    }, 3000);
  }

  /**
   * Removes a file from the selected files list and updates the form control value.
   *
   * @param fileIndex - The index of the file to be removed.
   * @param file - The file object to be removed.
   *
   * This method performs the following actions:
   * - Finds the file to be removed from the `oldFiles` array.
   * - If the form control is enabled, it removes the file from the `selectedFiles` array and the form control value.
   * - Updates the `isSuccess` and `isHidden` flags based on the remaining selected files.
   * - Sets an error on the form control if no files are left.
   * - Deletes the file from the server.
   */
  removeFile(fileIndex: number, file: any) {
    if (this.control.enabled) {
      this.selectedFiles.splice(fileIndex, 1);
      this.control.value.splice(fileIndex, 1);
    }

    this.selectedFiles.length ? "" : (this.isSuccess = false);
    this.isHidden = false;
    this.selectedFiles.length ? "" : this.setError({ required: true });
    this.deletedFile = file;
  }

  /**
   * Saves the provided files by calling an API and processing the response.
   *
   * @param files - An array of File objects to be saved.
   *
   * The method performs the following steps:
   * 1. Calls an API with the provided files.
   * 2. Subscribes to the API response.
   * 3. On successful response:
   *    - Maps the response to update the selected files with additional information.
   *    - Converts the file size.
   *    - Updates the old files list.
   *    - Sets the form control value based on the number of files.
   *    - Marks the form control as dirty and clears any errors.
   *    - Resets the file input and updates the success state.
   *    - Emits an upload event with the response.
   * 4. On error response:
   *    - Sets the failure state.
   */
  saveFile(files: File[]) {
    this.callingAPI(files).subscribe({
      next: (res: Attachment[]) => {
        res.map((file) => {
          let selectedFile: any = this.selectedFiles.find((selectedFile) => selectedFile.fileName === file.name);
          let selectedIndex = this.selectedFiles.indexOf(selectedFile);

          let finalFile: any = {
            fileName: file.name,
            size: file.fileSize,
            url: this.downloadUrl + file.id,
            extension: file.documentType.toLowerCase(),
            fileId: file.id,
            fileUrl: this.downloadUrl,
            isSuccess: true,
            isPublic: this.isPublic,
          };

          if (this.isCustom) {
            finalFile["content"] = selectedFile["content"];
          }

          this.convertSize(finalFile);
          this.oldFiles.push(finalFile);
          this.selectedFiles[selectedIndex].isSuccess = true;
          // this.selectedFiles.splice(selectedIndex, 1, finalFile);
        });

        if (this.maxFilesNumbers === 1 && this.saveUrl) {
          this.control.setValue(this.oldFiles[0].fileId);
        } else {
          this.control.setValue(this.oldFiles);
        }

        this.control.markAsDirty();
        this.control.setErrors(null);

        this.uploadedFiles = [];
        this.fileInput.nativeElement.value = "";
        this.isFailed = false;
        this.isSuccess = true;
        this.uploadEvent.emit(res);
      },
      error: () => {
        this.isFailed = true;
      },
    });
  }

  /**
   * Uploads an array of files to the server.
   *
   * This method creates a FormData object and appends each file to it.
   * It then calls the `upload` method of the `attachmentService` to upload the files.
   *
   * @param files - An array of files to be uploaded.
   * @returns An observable that emits the server's response.
   */
  callingAPI(files: any[]) {
    let formData = new FormData();

    for (let index = 0; index < files.length; index++) {
      formData.append("files", files[index], files[index].name);
    }

    return this.attachmentService.upload(formData, this.isPublic);
  }

  /**
   * Deletes a file from the server.
   *
   * This method uses the `attachmentService` to delete an attachment by its `fileId`.
   * Once the deletion is successful, it emits a `deleteEvent` with the deleted file.
   *
   * @param file - The file object to be deleted, which contains the `fileId`.
   * @returns void
   */
  deleteFileFromServer(file: File): void {
    this.attachmentService.deleteAttachment(file.fileId).subscribe(() => this.deleteEvent.emit(file));
  }

  finalDelete() {
    if (this.deletedFile) this.deleteFileFromServer(this.deletedFile);
  }
}
