@if (form) {
  <div class="pb-4" [formGroup]="form">
    <div class="row">
      <!-- totalNumberOfCards -->
      <div class="col-sm-6 col-lg-4 col-xl-3">
        <app-input-count label="FIELDS.TOTAL_NO_OF_CARDS" formControlName="totalNumberOfCards" />
        <span class="d-block text-note font-12 mt-3">{{ "ALERT.COMPANY_CARDS" | translate: { number: cardCost } }}</span>
      </div>

      <!-- confirm -->
      @if (!itemId) {
        <div class="col-sm-6 col-lg-4 col-xl-3">
          <app-button
            btnLabel="ACTIONS.CONFIRM"
            btnClass="mt-3"
            (onClick)="confirmCardsCount()"
            color="primary"
            [disabled]="membershipCards.length === totalNumberOfCards"
          />
        </div>
      }
    </div>

    <!-- membership cards -->
    @if (membershipCards.controls.length) {
      <section class="membership-cards" formArrayName="membershipCards">
        <div class="row mt-4 mb-3">
          <div class="col-12 d-flex align-items-center gap-3">
            <h3 class="m-0 font-16 fw-bold text-black pe-4 pb-2 border-2 border-bottom d-flex w-max">
              {{ "FRIENDS_OF_OMAN_MUSEUM.MEMBERSHIP_CARDS" | translate }}
            </h3>
          </div>
        </div>

        @for (membershipCard of membershipCards.controls; track $index) {
          <div class="row mt-3" [formGroupName]="$index">
            <!-- number -->
            <div class="col-sm-5 col-lg-4">
              <app-input-count label="FIELDS.NO_OF_MEMBERS" formControlName="number" />
            </div>

            <!-- nationalityId -->
            <div class="col-sm-5 col-lg-4">
              <app-dropdown label="FIELDS.NATIONALITY" formControlName="nationalityId" [options]="nationalities()!" />
            </div>

            <!-- remove -->
            <div class="col-sm-2 col-lg-4">
              @if (membershipCards.controls.length - 1 <= $index) {
                <app-button
                  btnLabel="ACTIONS.ADD"
                  btnClass="without-shadow mt-2"
                  color="primary"
                  (onClick)="addCard()"
                  [disabled]="membershipCards.controls.length >= totalNumberOfCards"
                />
              } @else {
                <app-button btnClass="without-shadow border-radius-0 mt-2" color="primary" (onClick)="removeCard($index)">
                  <em class="db-icon-minus font-20"></em>
                </app-button>
              }
            </div>
          </div>
        }

        @if (membershipCards.errors) {
          <mat-error>
            {{ membershipCards.errors | validationHandler }}
          </mat-error>
        }
      </section>
    }

    <hr class="mt-4" />
    <!-- contact details -->
    <section class="contact-details">
      <div class="row row-gap-4 mt-4" formGroupName="user">
        <div class="col-12">
          <h3 class="m-0 font-16 fw-bold text-black pe-4 pb-2 border-2 border-bottom d-flex w-max">
            {{ "FRIENDS_OF_OMAN_MUSEUM.CONTACT_DETAILS" | translate }}
          </h3>
        </div>

        <!-- nameEn -->
        <div class="col-sm-6 col-lg-4">
          <app-input-text label="USER.NAME_EN" formControlName="nameEn" />
        </div>

        <!-- nameAr -->
        <div class="col-sm-6 col-lg-4">
          <app-input-text label="USER.NAME_AR" formControlName="nameAr" />
        </div>

        <!-- userName -->
        <div class="col-sm-6 col-lg-4">
          <app-input-text label="FIELDS.USERNAME" formControlName="userName" />
        </div>

        <!-- dateOfBirth -->
        <div class="col-sm-6 col-lg-4">
          <app-datepicker label="FIELDS.DATE_OF_BIRTH" formControlName="dateOfBirth" [maxDate]="today" />
        </div>

        <!-- email -->
        <div class="col-sm-6 col-lg-4">
          <app-input-text label="FIELDS.EMAIL" formControlName="email" contentType="email" />
        </div>

        <!-- phone -->
        <div class="col-sm-6 col-lg-4">
          <app-input-text label="FIELDS.PHONE" formControlName="phone" contentType="number">
            <span class="bg-transparent" matPrefix>+968</span>
          </app-input-text>
        </div>
      </div>
    </section>
  </div>
}
