import { Router } from "@angular/router";
import { toSignal } from "@angular/core/rxjs-interop";
import { TranslateModule } from "@ngx-translate/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Component, inject, Injector, runInInjectionContext, Signal, signal, ViewEncapsulation } from "@angular/core";

import { AuthService, customValidations, Dropdown, LookupsService, mustMatch } from "src/app/core";
import { InputTextComponent, ButtonComponent, DatepickerComponent, DropdownComponent, CheckboxComponent } from "src/app/shared";

@Component({
  selector: "app-register",
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    InputTextComponent,
    ButtonComponent,
    DatepickerComponent,
    DropdownComponent,
    CheckboxComponent,
  ],
  templateUrl: "./register.component.html",
  styleUrl: "./register.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent {
  private fb = inject(FormBuilder);
  private router = inject(Router);
  private injector = inject(Injector);
  private authService = inject(AuthService);
  private lookupsService = inject(LookupsService);

  registerForm!: FormGroup;
  showPassword = false;
  today = new Date();

  countries = toSignal(this.lookupsService.getCountries());
  governorates: Signal<Dropdown[] | undefined> = signal([]);
  states: Signal<Dropdown[] | undefined> = signal([]);
  genders = toSignal(this.lookupsService.getGenders());

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.initForm();
  }

  initForm() {
    this.registerForm = this.fb.group(
      {
        nameEn: [null, [Validators.required]],
        nameAr: [null, [Validators.required]],
        email: [null, [Validators.required, customValidations.email()]],
        phone: [null, [Validators.required, Validators.minLength(8)]],
        gender: [null, [Validators.required]],
        dateOfBirth: [null, [Validators.required]],
        employmentType: [1],
        isStudent: [false],
        isOmanResident: [true],

        countryId: [null, [Validators.required]],
        governorateId: [null, [Validators.required]],
        stateId: [null, [Validators.required]],

        password: [null, [Validators.required, Validators.minLength(8)]],
        confirmPassword: [null, [Validators.required, Validators.minLength(8)]],
      },
      {
        validators: mustMatch("password", "confirmPassword"),
      },
    );
  }

  getGovernorates(id: number) {
    runInInjectionContext(this.injector, () => (this.governorates = toSignal(this.lookupsService.getGovernorates({ id }))));
  }

  getStates(id: number) {
    runInInjectionContext(this.injector, () => (this.states = toSignal(this.lookupsService.getStates({ id }))));
  }

  register() {
    this.authService.register(this.registerForm.value).subscribe({
      next: (data) => {
        localStorage.setItem("username", this.registerForm.value.email);
        localStorage.setItem("phone", this.registerForm.value.phone);

        if (data["code"] === 1) {
          this.router.navigateByUrl("/auth/verify");
        } else {
          this.router.navigateByUrl("/auth/login");
        }
      },
    });
  }
}
