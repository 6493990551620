<mat-toolbar class="page-padding" (isEnglish)="isEnglish = $event">
  <img
    class="mx-3 cursor-pointer"
    src="assets/images/logo-with-text.png"
    alt="Logo Photo"
    [width]="isMobileScreen ? 60 : 120"
    routerLink="/"
  />

  <span class="flex-grow-1"></span>

  <mat-list class="d-flex align-items-center gap-3 p-0" role="list">
    <!-- changeLanguage -->
    <mat-list-item class="p-0 overflow-visible me-sm-4" role="listitem">
      <app-button
        [btnLabel]="isLargeScreen ? 'app_language' : ''"
        btnClass="without-shadow font-toggle"
        iconPosition="left"
        (onClick)="changeLanguage()"
      >
        <em class="db-icon-world text-primary font-18"></em>
      </app-button>
    </mat-list-item>

    <!-- home -->
    <mat-list-item class="p-0 overflow-visible" role="listitem">
      <a routerLink="/">
        <app-button btnClass="without-shadow font-14 h-40 px-4" color="primary" [btnLabel]="'HOME'" />
      </a>
    </mat-list-item>
  </mat-list>
</mat-toolbar>
