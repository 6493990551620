import { Injectable } from "@angular/core";
import saveAs from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"; // For table formatting in jsPDF

const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
  providedIn: "root",
})
export class ExportService {
  excel<T extends {}>(json: T[], excelFileName: string): void {
    const worksheet = XLSX.utils.json_to_sheet(json);
    // Calculate column widths based on content
    const columnWidths = this.calculateColumnWidths(json);
    worksheet["!cols"] = columnWidths.map((width) => ({ width }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "exported-data");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    this.saveAs(excelBuffer, `${excelFileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`, EXCEL_TYPE);
  }

  pdf(data: any, fileName: string): void {
    this.saveAs(data, `${fileName}.pdf`, "application/pdf");
  }


    /**
   * @param json `Record<string, unknown>[]`
   * @param pdfFileName `string`
   * @description Generates a PDF from the exported data
   */
    generatePdfFromData<T extends {}>(json: T[], pdfFileName: string): void {
      const doc = new jsPDF();

      // Step 1: Extract headers and rows from the JSON data
      const headers = Object.keys(json[0] || {}); // Use the first row to extract headers
      const rows = json.map((row) => headers.map((header) => (row as Record<string, any>)[header]));

      // Step 2: Add a title to the PDF
      doc.text(`${pdfFileName} Report`, 10, 10);

      // Step 3: Use jsPDF-AutoTable to add the table
      autoTable(doc, {
        startY: 20,
        head: [headers],
        body: rows,
        theme: "striped",
        styles: {
          // overflow: "linebreak", // Handle cell overflow
          fontSize: headers.length > 8 ? 4 : 10, // Smaller font if many columns
        },
        headStyles: {
          fillColor: [184, 89, 33], // Header color
        },
        columnStyles: {
          0: { cellWidth: 'auto' }, // Make the first column auto-adjust
        },
        tableWidth: 'auto', // Fit wide tables, stretch narrow ones
        margin: { left: 0, right: 0 }, // Add margins
      });

      // Step 4: Save the PDF
      doc.save(`${pdfFileName}.pdf`);
    }

  /**
   * @param data `Record<string, unknown>[]`
   * @returns `number[]`
   * @description Calculate column widths based on content
   */
  private calculateColumnWidths<T extends Record<string, unknown>>(data: T[]): number[] {
    let columnWidths: number[] = [];

    data.forEach((row) => {
      Object.keys(row).forEach((key, index) => {
        const columnLength =Math.max (String(row[key]).length , key.length);

        columnWidths[index] = Math.max(columnWidths[index] || 0, columnLength * 1.2); // Adjust the multiplier for a better fit
      });
    });

    return columnWidths;
  }

  /**
   *
   * @param buffer `BlobPart`
   * @param fileName `string`
   * @param type `string`
   * @description Save the file as a blob
   */
  private saveAs(buffer: BlobPart, fileName: string, type: string): void {
    const data: Blob = new Blob([buffer], { type });
    // let url = window.URL.createObjectURL(data);
    saveAs(data, fileName);
  }
}
