import { arEG, enUS } from "date-fns/locale";
import { MatCalendar } from "@angular/material/datepicker";
import { DateAdapter } from "@angular/material/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { format, startOfMonth } from "date-fns";
import { ChangeDetectorRef, Component, inject } from "@angular/core";

import { EventsService } from "src/app/features/admin";
import { ButtonComponent } from "src/app/shared";
import { TranslationService } from "src/app/core";

@Component({
  selector: "app-custom-calendar-header",
  standalone: true,
  imports: [ButtonComponent],
  template: `
    <div class="mat-calendar-header p-0">
      <div class="mat-calendar-controls d-flex align-items-center justify-content-between w-100 m-0 my-3 px-3 px-xl-4">
        <app-button btnClass="without-shadow" (onClick)="previousClicked('month')">
          <em class="db-icon-chevron-right rotate cursor-pointer text-primary font-28"></em>
        </app-button>

        <span class="custom-datepicker-header-label text-primary fw-bold font-24">{{ periodLabel }}</span>

        <app-button btnClass="without-shadow" (onClick)="nextClicked('month')">
          <em class="db-icon-chevron-left rotate cursor-pointer text-primary font-28"></em>
        </app-button>
      </div>
    </div>
  `,
})
export class CustomCalendarHeaderComponent {
  private cdr = inject(ChangeDetectorRef);
  private _calendar = inject(MatCalendar<Date>);
  private _dateAdapter = inject(DateAdapter<Date>);
  private eventsService = inject(EventsService);
  private translationService = inject(TranslationService);

  get periodLabel() {
    return format(this._calendar.activeDate, "MMMM yyyy", { locale: this.translationService.isEnglish ? enUS : arEG });
  }

  constructor() {
    this._calendar.stateChanges.pipe(takeUntilDestroyed()).subscribe(() => this.cdr.markForCheck());
    this.translationService.currentLanguage$.pipe(takeUntilDestroyed()).subscribe(() => this.cdr.markForCheck());
  }

  previousClicked(mode: "month" | "year") {
    this._calendar.activeDate =
      mode === "month"
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);

    this.eventsService.monthChanged.set(startOfMonth(this._calendar.activeDate));
  }

  nextClicked(mode: "month" | "year") {
    this._calendar.activeDate =
      mode === "month"
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);

    this.eventsService.monthChanged.set(startOfMonth(this._calendar.activeDate));
  }
}
