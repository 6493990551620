import { AttachmentResponse, Lookup } from "src/app/core";
import { Hall } from "src/app/features";

export interface EventPayload {
  id?: number;
  eventTypeId: number;
  museumBuildingId: number;
  hallId: number;
  titleEn: string;
  titleAr: string;
  descriptionAr: string;
  descriptionEn: string;

  eventTimes: EventTime[];

  oneEventDaysTypeId: number;
  oneEventDifferentDays: boolean;
  oneEventContinuesDays: boolean;

  reservedSeatsMuseumFriends: number;
  invitationEmails: string[] | { email: string }[];

  eventStatusId?: number;
  isPublic: boolean;
  mainPhoto: AttachmentResponse[];
  coverPhoto: AttachmentResponse[];
  otherPhotos: AttachmentResponse[];
  attachments: AttachmentResponse[];
  ticketPrices: TicketPrice[];
  eventAudiences: EventAudience[] | number[];
}

export interface Event extends Omit<EventPayload, "eventAudiences"> {
  eventTime: EventTime;
  availableBasicSeats: number;
  availablePremiumSeats: number;
  coverPhotoUrl: string;
  mainPhotoUrl: string;
  governorate: Lookup;
  state: Lookup;
  createdByUser: string;
  hall: Hall;
  eventType: Lookup;

  eventTypeEn: string;
  eventTypeAr: string;
  hallEn: string;
  hallAr: string;
  eventNatureEn: string;
  eventNatureAr: string;
  audienceTypes: Lookup[];

  eventStatus: Lookup;
  museumBuilding: Lookup;
  eventCategory: Lookup;
  eventNature: Lookup;
  eventAudiences: (EventAudience & { audienceType: Lookup })[] | number[];
}

export interface EventAudience {
  audienceTypeId: number;
  eventId: number;
}

export interface EventTime {
  eventId: number;
  eventDate: string | string[];
  dateFrom: string;
  dateTo: string;
  timeFrom: string;
  timeTo: string;
}

export interface TicketPrice {
  id?: number;
  ticketTypeId: number;
  ticketTypeNameEn?: string;
  ticketTypeNameAr?: string;
  ticketTypeCode?: string;
  quantity: number;
  price: number;
  eventId: number;
}

export interface EventInvitation {
  eventId: number;
  recipients: Record<string, string>;
}

export interface EventMessage {
  eventId: number;
  subject: string;
  email: string[];
  phoneNumber: string[];
  message: string;
  notificationType: NotificationType;
}

export enum NotificationType {
  Email = 1,
  SMS = 2,
}
