import { RouterLink } from "@angular/router";
import { MatListModule } from "@angular/material/list";
import { TranslateModule } from "@ngx-translate/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { Component, HostListener, inject } from "@angular/core";

import { TranslationService } from "src/app/core";
import { ButtonComponent, IsEnglishDirective } from "src/app/shared";

@Component({
  selector: "app-auth-header",
  standalone: true,
  imports: [MatToolbarModule, MatListModule, TranslateModule, IsEnglishDirective, RouterLink, ButtonComponent],
  templateUrl: "./auth-header.component.html",
  styleUrl: "./auth-header.component.scss",
})
export class AuthHeaderComponent {
  private translateService = inject(TranslationService);

  isEnglish = true;
  isMobileScreen = innerWidth <= 580;
  isLargeScreen = innerWidth >= 992;

  changeLanguage() {
    this.translateService.changeLanguage();
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event: Event) {
    this.isLargeScreen = (event.target as Window).innerWidth >= 992;
    this.isMobileScreen = (event.target as Window).innerWidth <= 580;
  }
}
