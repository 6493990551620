import { Routes } from "@angular/router";

import { bookRoutes } from "../book-service";
import { exhibitionsAndEventsRoutes } from "../exhibitions-and-events";

export const servicesRoutes: Routes = [
  {
    path: "",
    loadComponent: () => import("./pages/services/services.component").then((c) => c.ServicesComponent),
  },
  {
    path: "book",
    children: [{ path: "", redirectTo: "/services", pathMatch: "full" }, ...bookRoutes],
  },
  {
    path: "exhibitions-and-events",
    children: exhibitionsAndEventsRoutes,
  },
];
