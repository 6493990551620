import { add, format, parse } from "date-fns";
import { arEG } from "date-fns/locale";
import { Lookup } from "../interfaces";
import { DateFormats } from "../enums";

export const generateTimeHours = ({ time = "", workingHours = 8, minutesPerOption = 60 }) => {
  let todayBeggining = parse(time, DateFormats.time, new Date());

  let hoursOptions: Lookup[] = [
    {
      id: `${format(todayBeggining, DateFormats.time)} ${format(add(todayBeggining, { minutes: minutesPerOption }), DateFormats.time)}`,
      code: `${format(todayBeggining, DateFormats.ISO)}`,
      nameEn: `${format(todayBeggining, DateFormats.timezone_h)} - ${format(add(todayBeggining, { minutes: minutesPerOption }), DateFormats.timezone_h)}`,
      nameAr: `${format(add(todayBeggining, { minutes: minutesPerOption }), DateFormats.timezone_h, { locale: arEG })} - ${format(todayBeggining, DateFormats.timezone_h, { locale: arEG })}`,
    },
  ];

  for (let index = 1; index <= workingHours * (60 / minutesPerOption); index++) {
    todayBeggining = add(todayBeggining, { minutes: minutesPerOption });

    hoursOptions.push({
      id: `${format(todayBeggining, DateFormats.time)} ${format(add(todayBeggining, { minutes: minutesPerOption }), DateFormats.time)}`,
      code: `${format(todayBeggining, DateFormats.ISO)}`,
      nameEn: `${format(todayBeggining, DateFormats.timezone_h)} - ${format(add(todayBeggining, { minutes: minutesPerOption }), DateFormats.timezone_h)}`,
      nameAr: `${format(add(todayBeggining, { minutes: minutesPerOption }), DateFormats.timezone_h, { locale: arEG })} - ${format(todayBeggining, DateFormats.timezone_h, { locale: arEG })}`,
    });
  }

  return hoursOptions;
};
